import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop } from 'vue-property-decorator';
import { ODataFilterItem } from '../../../shared/models/shared/odata-filter-object';

@Component
export default class VDataTableFilterComponent extends AuthComponentBase {
    @Prop({ default: false }) readonly showFilter: false;
    @Prop({ default: [] }) readonly headers;

    awaitingSearch: boolean = false;
    filter: any[] = [];

    mounted() { 
        const getClosest = (elem, selector) => {
            for (; elem && elem !== document; elem = elem.parentNode) {
                if (elem.matches(selector)) return elem;
            }
            return null;
        };
        const table = getClosest(this.$refs.filter, 'table');
        if (window.innerWidth > 600 && table) { table.style.tableLayout = 'fixed'; } 
    }

    onSearchChanged() {
        if (!this.awaitingSearch) {
            this.awaitingSearch = true;
            setTimeout(() => {
                this.$emit('filterChanged', this.returnFilterItems());

                this.awaitingSearch = false;
            }, 1000); // 1 sec delay for typing 
        }
    }

    returnFilterItems(): ODataFilterItem[] {
        let filterItems: ODataFilterItem[] = [];
        const that = this;

        Object.keys(this.filter).forEach(key => {
            const val = that.filter[key];
            const header = that.headers.filter(h => h.value === key)[0];

            let newFilterItem: ODataFilterItem = {
                columnName: key,
                operator: header.filterOperator,
                value: val
            };

            filterItems.push(newFilterItem);
        });

        return filterItems;
    }

    clearFilter() { 
        this.filter = [];
        this.$emit('filterChanged', []);
    }
}